<template>
    <div class="page-container">
        <div class="page-top">
            <search-form :searchForm="tableConfig.searchForm" @handleQuery="handleQuery" :buttons="buttonConfig"
                @handleButtonClick="callMethod"></search-form>
        </div>
        <div class="page-table">
            <grid-table ref="gridTable" :tableConfig="tableConfig" @selectionChange="selectionChange">
            </grid-table>
        </div>
        <div class="page-dialog">
            <purchase-return-order-edit ref="purchaseReturnOrderEdit" v-if="dialogVisible"
                @handleQuery="handleQuery"></purchase-return-order-edit>
        </div>
        <div class="page-dialog">
            <edit-dialog ref="editDialog" v-if="dialogVisible" :formConfig="editFormConfig"
                @handleQuery="handleQuery"></edit-dialog>
        </div>
    </div>
</template>

<script>
import { submit, exportFile } from "@/api/base";
import PurchaseReturnOrderEdit from "./components/PurchaseReturnOrderEdit.vue";
export default {
    name: "purchaseReturnOrder",
    components: {
        PurchaseReturnOrderEdit,
    },
    data() {
        return {
            buttonConfig: [...(this.$route.meta.pageConfig.buttons || [])],
            tableConfig: { ...(this.$route.meta.pageConfig.table || {}) },
            selectRows: [],
            dialogVisible: false,
            editFormConfig: {},
        };
    },
    mounted() {
        this.$nextTick(async () => {
            if (this.tableConfig.searchForm.url) {
                const { data } = await submit(this.tableConfig.searchForm.url);
                const { items } = this.getFormRules(
                    this.tableConfig.searchForm.items,
                    data.InitData
                );
                this.$set(this.tableConfig.searchForm, "items", items);
            }
        });
        this.handleQuery();
    },
    methods: {
        handleSearch(_this, button) {
            _this.handleQuery();
        },
        handleShowDialog(_this, button) {
            if (
                button.isNeedSelectRow &&
                (!_this.selectRows || _this.selectRows.length == 0)
            ) {
                _this.$baseMessage("请选择数据", "error");
                return false;
            }
            if (
                button.isNeedSelectRow &&
                !button.isMultiSelectRow &&
                _this.selectRows.length > 1
            ) {
                _this.$baseMessage("只能选择一行", "error");
                return false;
            }
            _this.dialogVisible = true;
            _this.$nextTick(() => {
                _this.editFormConfig = { ...button.bindForm };
                _this.$refs.editDialog.init(
                    button.label,
                    _this.getTableParam(
                        _this.selectRows,
                        button.isNeedRowKey,
                        button.isMultiSelectRow,
                        _this.tableConfig.rowKey,
                        button.paramJson
                    )
                );
            });
        },
        handleToExcute(_this, button) {
            if (
                button.isNeedSelectRow &&
                (!_this.selectRows || _this.selectRows.length == 0)
            ) {
                _this.$baseMessage(`请选择要${button.label}的数据`, "error");
                return false;
            }
            if (
                button.isNeedSelectRow &&
                !button.isMultiSelectRow &&
                _this.selectRows.length > 1
            ) {
                _this.$baseMessage("只能选择一行", "error");
                return false;
            }
            if (button.isNeedConfirm) {
                _this.$baseConfirm(`确定选中项要${button.label}吗？`, null, () => {
                    this.handleExecute(_this, button);
                });
            } else {
                this.handleExecute(_this, button);
            }
        },
        async handleExecute(_this, button) {
            const { msg } = await submit(
                button.url,
                _this.getTableParam(
                    _this.selectRows,
                    button.isNeedRowKey,
                    button.isMultiSelectRow,
                    _this.tableConfig.rowKey,
                    button.paramJson
                )
            );
            if (msg) {
                _this.$baseMessageBox(
                    `${button.label}成功，${msg}`,
                    `${button.label}提示`
                );
            } else {
                _this.$baseMessage(`${button.label}成功`, "success");
            }
            _this.handleQuery();
        },
        handleQuery() {
            this.$refs.gridTable.fetchData();
        },
        selectionChange(selection) {
            this.selectRows = selection;
        },
        callMethod(button) {
            let methods = this.$options.methods;
            const _this = this;
            methods[button.method](_this, button);
        },
        search(_this) {
            _this.handleQuery();
        },
        add(_this) {
            _this.showDialog(_this, "add");
        },
        edit(_this) {
            _this.showDialog(_this, "edit");
        },
        showDetail(_this) {
            _this.showDialog(_this, "detail");
        },
        showDialog(_this, type) {
            let purchaseReturnID = 0;
            if (type != "add") {
                if (!_this.selectRows || _this.selectRows.length == 0) {
                    _this.$baseMessage("请选择数据", "error");
                    return;
                }
                if (_this.selectRows.length > 1) {
                    _this.$baseMessage("只能选择一行", "error");
                    return;
                }
                if (type != "detail" && _this.selectRows[0].ReturnStatus == 1) {
                    _this.$baseMessage(
                        "该采购退货单已审核" + (type == "edit" ? "，不可编辑" : ""),
                        "error"
                    );
                    return;
                }
                purchaseReturnID = _this.selectRows[0].PurchaseReturnID;
            }
            _this.dialogVisible = true;
            _this.$nextTick(() => {
                _this.$refs.purchaseReturnOrderEdit.init(type, purchaseReturnID);
            });
        },
        async export(_this, button) {
            let param = {};
            if (
                button.isNeedSelectRow &&
                (!_this.selectRows || _this.selectRows.length == 0)
            ) {
                _this.$baseMessage(`请选择要${button.label}的数据`, "error");
                return false;
            }
            if (
                button.isNeedSelectRow &&
                !button.isMultiSelectRow &&
                _this.selectRows.length > 1
            ) {
                _this.$baseMessage("只能选择一行", "error");
                return false;
            }
            if (!button.isMultiSelectRow) {
                param[_this.tableConfig.rowKey] =
                    _this.selectRows[0][_this.tableConfig.rowKey];
            } else {
                param[_this.tableConfig.rowKey + "s"] = _this.selectRows
                    .map((row) => row[_this.tableConfig.rowKey])
                    .join();
            }
            const { data } = await exportFile(button.url, param);
            const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = data.FileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
        },
    },
};
</script>

<style lang="scss" scoped>
.page-top {
    padding: 6px 14px 0 14px;
}
</style>
